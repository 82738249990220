<section
    *ngIf="{
        contentGeneration: contentGeneration$ | async,
        theme: theme$ | async,
    } as $"
    class="flex h-full flex-col gap-y-24"
>
    <header class="flex w-full shrink-0 items-center justify-center gap-x-8">
        <interacta-image
            alt=""
            [classes]="'size-40'"
            [src]="
                $.theme === 'dark'
                    ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                    : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
            "
        ></interacta-image>
        <h5
            class="typo-t5"
            [innerHTML]="
                'AI.SECTION_TITLE'
                    | translate
                        : {
                              AIstring: wrapWithSpan('AI', 'text-text-primary'),
                          }
                    | safeHtml
            "
        ></h5>
    </header>

    <div class="relative z-0 flex size-full grow overflow-hidden">
        <div class="flex size-full flex-col overflow-y-auto">
            <interacta-form-field-v2
                class="mb-16 block w-full"
                [control]="control"
                [name]="'AI.CONTENT_GENERATION.INPUT_TEXT_LABEL' | translate"
            >
                <interacta-mention-delta-editor
                    class="interacta-ai-mention-delta-editor"
                    [control]="control"
                    [forceDisableAI]="true"
                    [withBorders]="true"
                />
            </interacta-form-field-v2>
            <div class="flex flex-wrap gap-12">
                <button
                    interacta-button
                    type="button"
                    [appearance]="'border'"
                    [bgColor]="'textColor'"
                    [icon]="'megaphone'"
                    [label]="
                        'AI.CONTENT_GENERATION.ACTIONS.TONE_OF_VOICE'
                            | translate
                    "
                    [size]="'regular'"
                    (click)="
                        currentSection.set(
                            AIContentGenerationType.TONE_OF_VOICE
                        )
                    "
                ></button>
                <button
                    interacta-button
                    type="button"
                    [appearance]="'border'"
                    [bgColor]="'textColor'"
                    [icon]="'short-text'"
                    [label]="
                        'AI.CONTENT_GENERATION.ACTIONS.SUMMARIZE' | translate
                    "
                    [size]="'regular'"
                    (click)="
                        currentSection.set(AIContentGenerationType.SUMMARIZE)
                    "
                ></button>
                <button
                    interacta-button
                    type="button"
                    [appearance]="'border'"
                    [bgColor]="'textColor'"
                    [icon]="'post'"
                    [label]="'AI.CONTENT_GENERATION.ACTIONS.EXPAND' | translate"
                    [size]="'regular'"
                    (click)="currentSection.set(AIContentGenerationType.EXPAND)"
                ></button>
            </div>
        </div>
        @for (
            section of [
                AIContentGenerationType.TONE_OF_VOICE,
                AIContentGenerationType.SUMMARIZE,
                AIContentGenerationType.EXPAND,
            ];
            track $index
        ) {
            <interacta-ai-panel-content-generation-detail
                class="bg-surface-B absolute z-10 size-full transition-transform"
                [activeSection]="currentSection() === section"
                [attr.aria-hidden]="currentSection() !== section"
                [attr.inert]="currentSection() !== section ? '' : null"
                [initialContent]="control | formControlValueV2$ | async"
                [ngClass]="{
                    'translate-x-0': currentSection() === section,
                    'translate-x-[110%]': currentSection() !== section,
                }"
                [section]="section"
                [theme]="$.theme"
                (backClicked)="currentSection.set('MAIN')"
            />
        }
    </div>
</section>
