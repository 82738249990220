import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnDestroy,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterMap } from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import { getContentGenerationTypeLabel } from '@modules/ai/helpers/ai.utils';
import { AIContentGenerationType } from '@modules/ai/models/ai.model';
import { selectActiveInitialContentGeneration } from '@modules/ai/store/ai.selectors';
import { wrapWithSpan } from '@modules/core/helpers/i18n.utils';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { Store } from '@ngrx/store';
import { Delta } from 'quill/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'interacta-ai-panel-content-generation',
    templateUrl: './ai-panel-content-generation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        .interacta-ai-mention-delta-editor .ql-comment-editor.ql-container {
            box-sizing: border-box;
            min-height: 104px;
            max-height: 484px;
        }
    `,
    encapsulation: ViewEncapsulation.None,
})
export class AIPanelContentGenerationComponent implements OnDestroy {
    private readonly store = inject(Store);
    private readonly destroy$ = new Subject<void>();

    readonly contentGeneration$: Observable<Delta | undefined | null>;
    readonly theme$: Observable<ThemeMode>;
    readonly currentSection = signal<'MAIN' | AIContentGenerationType>('MAIN');
    readonly control = new FormControl<Delta | null>(null);

    wrapWithSpan = wrapWithSpan;
    getContentGenerationTypeLabel = getContentGenerationTypeLabel;
    AIContentGenerationType = AIContentGenerationType;

    constructor() {
        this.contentGeneration$ = this.store.select(
            selectActiveInitialContentGeneration,
        );

        this.theme$ = this.store
            .select(UISelectors.theme)
            .pipe(filterMap((theme) => theme?.mode ?? undefined));

        this.contentGeneration$
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => this.control.patchValue(value ?? null));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
