<ng-container
    *ngIf="{
        theme: theme$ | async,
        history: historyMap$ | async,
        context: context$ | async,
        isFetching: isFetching$ | async,
        focusOnInput: focusOnInput$ | async,
        sessionId: sessionId$ | async,
        canAddAttachment: canAddAttachment$ | async,
    } as $"
>
    <section class="flex h-full flex-col gap-y-24">
        <header
            class="flex w-full shrink-0 gap-x-8"
            [ngClass]="{
                'items-center justify-center': threadId === MAIN_THREAD,
                'items-start': threadId !== MAIN_THREAD,
            }"
        >
            @if (threadId === MAIN_THREAD) {
                <interacta-image
                    [alt]="'AInteracta logo'"
                    [classes]="'size-40'"
                    [src]="
                        $.theme === 'dark'
                            ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                            : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
                    "
                />
                <h5
                    class="typo-t5"
                    [innerHTML]="
                        'AI.SECTION_TITLE'
                            | translate
                                : {
                                      AIstring: wrapWithSpan(
                                          'AI',
                                          'text-text-primary'
                                      ),
                                  }
                            | safeHtml
                    "
                ></h5>
            } @else {
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="'BUTTON.LABEL_BUTTON_BACK' | translate"
                    [icon]="'arrow-left'"
                    [matTooltip]="'BUTTON.LABEL_BUTTON_BACK' | translate"
                    (click)="goToThread()"
                ></button>
                <div>
                    <h5 class="typo-t5">Thread</h5>
                    @if (threadName) {
                        <p class="typo-sm text-text-mid-contrast">
                            {{ threadName }}
                        </p>
                    }
                </div>
            }
        </header>
        <div class="flex h-full min-h-0 grow overflow-hidden">
            @if ($.history | apply: objectIsEmpty) {
                <div class="flex w-full flex-col gap-y-24">
                    <h5 class="typo-t5 self-center">
                        {{ 'AI.SECTION_SUBTITLE' | translate }}
                    </h5>
                    <div class="flex flex-col gap-y-12 overflow-y-auto">
                        <interacta-ai-panel-tip
                            *ngIf="$.context.contextType === AIContextType.POST"
                            [type]="AIPanelTipType.HAVE_A_RESUME"
                            (tipButtonClicked)="
                                getResume('AI.INFO_ABOUT_POST_SUMMARY_REQUEST')
                            "
                        />
                        <interacta-ai-panel-tip
                            *ngIf="
                                $.context.workflowEnabled &&
                                $.context.contextType === AIContextType.POST
                            "
                            [type]="AIPanelTipType.QUESTIONS_ABOUT_WORKFLOWS"
                            (tipButtonClicked)="
                                startNewConversation(
                                    'AI.INFO_ABOUT_WORKFLOWS_REQUEST',
                                    'AI.INFO_ABOUT_WORKFLOWS_RESPONSE'
                                )
                            "
                        />
                        <interacta-ai-panel-tip
                            *ngIf="
                                $.context.workflowEnabled &&
                                $.context.contextType ===
                                    AIContextType.COMMUNITY
                            "
                            [type]="
                                AIPanelTipType.QUESTIONS_ABOUT_COMMUNITY_WORKFLOWS
                            "
                            (tipButtonClicked)="
                                startNewConversation(
                                    'AI.INFO_ABOUT_COMMUNITY_WORKFLOWS_REQUEST',
                                    'AI.INFO_ABOUT_WORKFLOWS_RESPONSE'
                                )
                            "
                        />
                        <interacta-ai-panel-tip
                            *ngIf="$.context.contextType === AIContextType.POST"
                            [type]="AIPanelTipType.ASK_ME_A_QUESTION"
                            (tipButtonClicked)="
                                startNewConversation(
                                    'AI.NEW_CONVERSATION_REQUEST',
                                    'AI.NEW_CONVERSATION_RESPONSE'
                                )
                            "
                        />
                        <interacta-ai-panel-tip
                            *ngIf="
                                $.context.contextType === AIContextType.GUIDE
                            "
                            [type]="AIPanelTipType.GUIDE_INTERACTA"
                            (tipButtonClicked)="
                                startNewConversation(
                                    'AI.INFO_ABOUT_INTERACTA_REQUEST',
                                    'AI.INFO_ABOUT_INTERACTA_RESPONSE'
                                )
                            "
                        />
                    </div>
                </div>
            } @else {
                @if (
                    $.history && $.history | apply: getHistoryArray;
                    as messages
                ) {
                    <div
                        #scrollContainer
                        class="flex min-h-0 grow flex-col gap-y-8 overflow-y-auto scroll-smooth"
                    >
                        @for (message of messages; track message.id) {
                            @if (message.value && $.theme) {
                                <interacta-ai-message
                                    class="w-full shrink-0"
                                    [contextType]="$.context.contextType"
                                    [item]="message.value"
                                    [last]="$last"
                                    [messageKey]="message.id"
                                    [theme]="$.theme"
                                    [threadId]="threadId"
                                    (goToThread)="goToThread($event)"
                                    (openAttachmentPreview)="
                                        openAttachmentPreview($event)
                                    "
                                    (retry)="
                                        retry(
                                            messages[$index - 1].value.message
                                        )
                                    "
                                    (scrollToBottom)="scrollToBottom()"
                                />
                            }
                        }
                        @if ($.isFetching) {
                            <div
                                class="bg-surface-A h-100 typo-sm shadow-chat flex min-h-0 w-full shrink-0 gap-x-8 rounded-r-2xl rounded-tl-2xl px-12 py-8"
                            >
                                <interacta-image
                                    class="shrink-0"
                                    [alt]="'AInteracta loader'"
                                    [classes]="'w-36 h-24'"
                                    [src]="
                                        $.theme === 'light'
                                            ? './assets/images-2.0/ai/ai-interacta-loader-animation.gif'
                                            : './assets/images-2.0/ai/ai-interacta-loader-animation_D.gif'
                                    "
                                />
                                <p class="flex grow flex-col gap-y-8">
                                    <span
                                        class="bg-surface-300 h-8 w-full animate-pulse rounded-3xl"
                                    ></span>
                                    <span
                                        class="bg-surface-300 h-8 w-1/2 animate-pulse rounded-3xl"
                                    ></span>
                                    <span
                                        class="bg-surface-300 h-8 w-2/3 animate-pulse rounded-3xl"
                                    ></span>
                                </p>
                            </div>
                        }
                    </div>
                }
            }
        </div>
        <div class="flex shrink-0 items-center gap-x-8">
            @if ($.canAddAttachment) {
                <interacta-ai-panel-chat-attachment-button
                    [postId]="$.context.contextId"
                    [theme]="$.theme"
                />
            }
            <input
                #input
                class="bg-surface-A typo-sm grow rounded-3xl p-12 transition-shadow duration-1000"
                type="text"
                [formControl]="questionControl"
                [ngClass]="{
                    'shadow-primary ': $.focusOnInput,
                    'shadow-none': !$.focusOnInput,
                }"
                [placeholder]="'AI.INPUT_PLACEHOLDER' | translate"
                (keyup.enter)="!$.isFetching && sendRequestToAI($.sessionId)"
            />
            @if (questionControl.value.length > 0 && !$.isFetching) {
                <button
                    interacta-icon-button
                    type="submit"
                    [attr.aria-label]="'AI.SEND_MESSAGE' | translate"
                    [bgColor]="'primary'"
                    [icon]="'paper-plane'"
                    [size]="'large'"
                    (click)="sendRequestToAI($.sessionId)"
                ></button>
            }
        </div>
    </section>
</ng-container>
